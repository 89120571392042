.App {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0E2841;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
}

.text-container {
  text-align: center;
  margin-bottom: 2rem;
}

h1 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 7rem;
  font-weight: 700;
  margin: 0;
  letter-spacing: -1px;
}

.tagline {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1rem;
  letter-spacing: 2px;
  opacity: 0.9;
}

button {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 1.5rem;
  position: relative;
  overflow: hidden;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

button:active {
  transform: translateY(0) scale(0.95);
}

/* Mobile devices */
@media (max-width: 768px) {
  h1 {
    font-size: 4rem;
  }

  .tagline {
    font-size: 1rem;
  }

  .text-container {
    max-width: 280px; /* mobile specific max-width */
}

  button {
    font-size: 0.8rem;
  }
}