.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
    z-index: 3;
    vertical-align: top;
  }
  
  .footer a {
    color: white;
    text-decoration: underline;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: bold;
    opacity: 0.8;
    transition: opacity 0.3s ease;
  }
  
  .footer a:hover {
    opacity: 1;
  }