.page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #0E2741;
    padding: 2rem;
}

.page-content {
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgb(255, 255, 255);
    padding: 2rem;
    border-radius: 8px;
    color: white;
    margin: 1rem;
    /* Add these properties for better scrolling */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

/* Style the scrollbar for webkit browsers */
.page-content::-webkit-scrollbar {
    width: 8px;
}

.page-content::-webkit-scrollbar-track {
    background: transparent;
}

.page-content::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}

.back-link {
    display: inline-block;
    margin-top: 2rem;
    color: white;
    text-decoration: underline;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.back-link:hover {
    opacity: 1;
}

.imprint-container {
    min-height: 100vh;
    background-color: #0E2741;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: white;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.imprint-content {
    text-align: center;
    max-width: 600px;
    line-height: 1.6;
}

.imprint-content h1 {
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 4rem;
}

.imprint-details {
    margin-bottom: 3rem;
}

.imprint-details p {
    font-size: 1rem;
    font-weight: 350;
    margin: 0.5rem 0;
    color: rgba(255, 255, 255, 0.9);
}

.imprint-content a {
    color: white;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.imprint-content a:hover {
    opacity: 0.8;
}

.back-link {
    display: inline-block;
    margin-top: 3rem;
    font-size: 0.9rem;
    color: white;
    text-decoration: none;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.back-link:hover {
    opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .imprint-content h1 {
        font-size: 2.5rem;
        margin-bottom: 3rem;
    }
    
    .imprint-details p {
        font-size: 0.9rem;
    }
}